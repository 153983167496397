<template>
  <div class="page-wrap text-center">
    <div class="session-form-hold">
      <v-card rounded="xl" class="">
        <div class="d-flex flex-column gap-3 pa-6 pb-3">
          <v-icon
            style="position: absolute; right: 15px; top: 15px"
            @click="help"
            size="20"
          >
            mdi-help-circle
          </v-icon>

          <div class="d-flex flex-column justify-center align-center">
            <v-icon size="50" class="mb-2">mdi-face-recognition</v-icon>
            <h2>Registro Facial</h2>
            <p
              class="text-13 mb-0"
              style="overflow-wrap: break-word; max-width: 350px"
            >
              Usaremos seu rosto para autorizar a entrada nos eventos que
              utilizam equipamentos de reconhecimento facial
            </p>
          </div>
          <div
            class="d-flex gap-4 align-self-center align-center opacity-transition"
          >
            <v-alert class="text-left mb-0" :type="alertType" text>
              <span>{{ alertMessage }}</span>
            </v-alert>
          </div>

          <div v-if="hasFace" class="px-4">
            <v-btn text @click="goToHome()">Voltar</v-btn>
          </div>
          <div v-else class="mb-2">
            <v-btn block color="success" @click="capture()"> Continuar </v-btn>
          </div>
        </div>
      </v-card>
    </div>
    <face-help-modal />
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import FaceHelpModal from "../../../../components/global/account/face/FaceHelpModal.vue";

export default {
  components: { FaceHelpModal },
  name: "Validate",
  metaInfo: {
    title: "Captura facial",
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    goToHome() {
      const redirect = this.$route.query.redirect || "/app";
      this.$router.push(redirect);
    },
    capture() {
      this.$router.push({
        path: "/auth/account/face/capture",
        query: { redirect: this.$route.query.redirect || undefined },
      });
    },
    help() {
      this.$emit("face-help");
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),

    hasFace() {
      return this.user?.Biometrics.find((b) => b.type === "FACE");
    },
    alertType() {
      return this.hasFace ? "success" : "error";
    },
    alertMessage() {
      return this.hasFace
        ? `Seu cadastro facial é válido até ${moment(
            this.hasFace.expiresAt
          ).format("DD/MM/YYYY")}`
        : "Seu cadastro está pendente. Por favor, faça o registro.";
    },
  },
  mounted() {
    if (this.$route.query.help) {
      this.help();
    }
  },
};
</script>
<style lang="scss">
.page-wrap {
  background: radial-gradient(
      circle at 50% 50%,
      rgba(0, 0, 0, 50%) 0%,
      rgba(0, 0, 0, 90%) 90vw
    ),
    url(/img/logo/auth-background.png), black;
  display: flex;
  background-size: cover;
  background-position: center;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.opacity-transition {
  transition: opacity 0.3s;
}

.face-container {
  div {
    border-radius: 16px;
    svg {
      border-radius: 16px;
    }
  }
}
</style>
